import React from 'react';
import './ProgressBar.css';
import logo from '../../Asset/svg/icon.svg';

function ProgressBar({ total, current }) {
    const progressPercentage = ((current - 1) / (total - 1)) * 100;

    const renderProgressPoints = () => {
        const points = [];
        for (let i = 0; i < total; i++) {
            const isReached = i < current;
            points.push(
                <div
                    key={i}
                    className={`progress-point ${isReached ? 'active' : ''}`}
                    style={{ left: `${(i / (total - 1)) * 100}%` }}
                />
            );
        }
        return points;
    };

    if (total === 1) {
        return <></>;
    }else{
        return (
            <div className="progress-container">
                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
                {renderProgressPoints()}
                <img src={logo} alt="logo" style={{ left: `${progressPercentage}%` }} />
            </div>
        );
    }
}

export default ProgressBar;
