import React, { useState} from 'react';
import './SlideAdress.css';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];

function SlideInput({ currentQuestion, transitionClass, linkedInput }) {
    const [autocomplete, setAutocomplete] = useState(null);
        
    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            let place = autocomplete.getPlace();
    
            let address = '',
                city = '',
                zip = '',
                country = '';
    
            if (place.address_components[0] && place.address_components[1]) {
                address = `${place.address_components[0].long_name} ${place.address_components[1].long_name}`;
            }
    
            for (let i = 0; i < place.address_components.length; i++) {
                let addr = place.address_components[i];
                if (addr.types[0] === 'locality') city = addr.long_name;
                if (addr.types[0] === 'postal_code') zip = addr.long_name;
                if (addr.types[0] === 'country') country = addr.long_name; // Récupération du pays
            }
    
            linkedInput(currentQuestion.name, {
                Address: address,
                City: city,
                ZipCode: zip,
                Country: country,
            });
        }
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyDsZ0rldhrOp0D3G6dlxbCi3Kwoojwo-88" libraries={libraries}>
            <div className={`Slide ${transitionClass}`}>
                <div className="inputDiv" key={currentQuestion.id}>
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <input type="text" name={currentQuestion.name} placeholder='' id={currentQuestion.name} required />
                    </Autocomplete>
                    <span></span>
                    <label htmlFor={currentQuestion.name}><h4>{currentQuestion.label}</h4></label>
                </div>
            </div>
        </LoadScript>
    );
}

export default SlideInput;
