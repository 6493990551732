import { createSlice } from '@reduxjs/toolkit';

const StoreSlice = createSlice({
    name: 'Store',
    initialState: {
        Name: "",
        Mail: "",
    },
    reducers: {
        sendNameStore: (state, action) => {
            state.Name = action.payload;
        },
        sendMailStore: (state, action) => {
            state.Mail = action.payload;
        }
    },
});

export const { sendNameStore, sendMailStore } = StoreSlice.actions;

export default StoreSlice.reducer;
