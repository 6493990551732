import React from 'react';
import './SlideRadio.css'

function SlideRadio({ currentQuestion, transitionClass, linkedInput, currentValue }) {
    return (
        <div className={`Slide ${transitionClass}`}>
            <h4>{currentQuestion.label}</h4>

            <div className='radioDiv' key={currentQuestion.id}>
                {currentQuestion.option.map((option, index) => (
                    <div className='radioDiv' key={index}>
                        <input
                        type="radio"
                        id={currentQuestion.name}
                        name={currentQuestion.name}
                        value={option}
                        onChange={e => linkedInput(e.target.name, e.target.value)}
                        checked={currentValue === option}
                        />
                        <label htmlFor={currentQuestion.name}>
                        {option}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
  }
  
  export default SlideRadio;