import React from 'react'
import './SlideInput.css'

function SlideInput({ currentQuestion, transitionClass, linkedInput, currentValue }) {

    const dateProps = currentQuestion.type === 'date' || currentQuestion.type === 'number' || currentQuestion.type === 'range' || currentQuestion.type === 'month' ? {
        min: currentQuestion.min,
        max: currentQuestion.max
    } : {};

    if((currentValue === "" || currentValue === undefined || currentValue === null || currentValue === NaN )&& currentQuestion.type === 'range' ) {
        currentValue = "3"
        linkedInput(currentQuestion.name, currentValue);
    }

    return (
        <div className={`Slide ${transitionClass}`}>
            {
                currentQuestion.type === 'range' ? (
                    <div className='RangeDiv' key={currentQuestion.id}>
                        <label htmlFor={currentQuestion.name}><h4>{currentQuestion.label} ( {currentValue} )</h4></label>
                        <input
                            type={currentQuestion.type}
                            id={currentQuestion.name}
                            name={currentQuestion.name}
                            onChange={e => linkedInput(e.target.name, e.target.value)}
                            value={currentValue}
                            required
                            {...dateProps}
                        />
                    </div>
                    
                ) : (
                    <div className='inputDiv' key={currentQuestion.id}>
                        <input
                            type={currentQuestion.type}
                            id={currentQuestion.name}
                            name={currentQuestion.name}
                            onChange={e => linkedInput(e.target.name, e.target.value)}
                            value={currentValue}
                            required
                            {...dateProps}
                        />
                        <span></span>
                        <label htmlFor={currentQuestion.name}><h4>{currentQuestion.label}</h4></label>
                    </div>
                )
            }
            
        </div>
    );
  }
  
  export default SlideInput;