import React, { useState, useEffect } from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { sendNameStore, sendMailStore } from '../../Redux/slice';
import logo from "../../Asset/svg/logoblack.svg";
import axios from 'axios';
import './Simulation.css';

function Simulation(props) {

    const { nameStoretemp } = useParams();
	const storeNameStore = useSelector((state) => state.nameStore.Name);
	const dispatch = useDispatch();

	let finalNameStore;

	if (nameStoretemp) {
		finalNameStore = nameStoretemp;
	} else if (storeNameStore) {
		finalNameStore = storeNameStore;
	} else {
		finalNameStore = "Siko Mobility";
	}

    const { Variable, functionVariable } = props;

    useEffect(() => {
        dispatch(sendNameStore(finalNameStore));

        console.log(finalNameStore);

        const fetchData = async () => {
            try {
                const response = await axios.get(`http://localhost:8000/api/merchant?Name=${finalNameStore}`);
                if (response.data.Email) {
                    dispatch(sendMailStore(response.data.Email));
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [finalNameStore, dispatch]);

    const [montantEmprunt, setMontantEmprunt] = useState('');
    const [apport, setApport] = useState('');
    const [dureeMois, setDureeMois] = useState(3);
    const [loyerMensuel, setLoyerMensuel] = useState(0);

    const montants = [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000,
        9000, 10000, 15000, 20000, 25000, 30000, 35000,
        40000, 50000
    ];

    // const marges = [
    //     120, 200, 270, 320, 400, 480, 560, 640,
    //     720, 800, 1050, 1200, 1500, 1500, 1750, 2000, 2500
    // ];

    const marges = [
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0
    ];

    const taux = [
        0.16, 0.14, 0.105, 0.105, 0.105, 0.075, 0.075, 0.075,
        0.075, 0.075, 0.075, 0.075, 0.075, 0.075, 0.075, 0.075, 0.075
    ];

    const calculerMarge = (montant) => {
        for (let i = 0; i < montants.length; i++) {
            if (montant <= montants[i]) {
                console.log(marges[i]);
                return marges[i];
            }
        }
        return marges[marges.length - 1]; // retourner la dernière marge si le montant est plus grand que le dernier montant dans `montants`
    };

    const calculerTauxRefinancing = (montant) => {
    
        for (let i = 0; i < montants.length; i++) {
            if (montant <= montants[i]) {
                console.log(taux[i]);
                return taux[i];
            }
        }
    
        return taux[taux.length - 1]; // retourner le dernier taux si le montant est plus grand que le dernier montant dans `montants`
    };

    const calculerLoyerMensuel = (principal, taux, temps) => {
        const tauxMensuel = taux / 12;
        const nbPaiements = temps;
        const denominateur = Math.pow(1 + tauxMensuel, nbPaiements) - 1;
        return (principal * tauxMensuel * Math.pow(1 + tauxMensuel, nbPaiements)) / denominateur;
    };

    useEffect(() => {
        const montant = parseFloat(montantEmprunt);
        const apportValue = (apport === '') ? 0 : parseFloat(apport);

        if (isNaN(montant) || montant === Infinity) {
            setLoyerMensuel(0);
            return;
        }

        const taux = calculerTauxRefinancing(montant);
        const marge = calculerMarge(montant);
        const principal = montant - apportValue + marge;
        const loyerMensuel = calculerLoyerMensuel(principal, taux, dureeMois);
        setLoyerMensuel(loyerMensuel);

        if (apportValue > montant) {
            setApport(montant.toString());
        }
    }, [montantEmprunt, apport, dureeMois]);

    return (
        <div className='BackContentSimulation'>
            
            <div className={`ContentSimulation ${Variable ? "ContentSimulationVariable" : ""}`}>
                {Variable && 
                    <>
                        <button class="closeButton" onClick={functionVariable}>✖</button>
                    </>
                }
                <a href="https://www.sikomobility.com/">
                    <img src={logo} alt="logo"/>
                </a>
                <h2>SIMULEZ VOTRE MENSUALITÉ {finalNameStore}</h2>
                <div className="inputDiv">
                    <input
                        type="number"
                        value={montantEmprunt}
                        id='Amount'
                        onChange={(e) => setMontantEmprunt(e.target.value)}
                    />
                    <span></span>
                    <label htmlFor="Amount">Montant à Financer : </label>
                </div>
                <div className="inputDiv">
                    <input
                        type="number"
                        value={apport}
                        max={montantEmprunt}
                        id='Apport'
                        onChange={(e) => setApport(e.target.value)}
                    />
                    <span></span>
                    <label htmlFor="Apport">Apport : </label>
                </div>
                <div className="RangeDiv">
                    <label htmlFor="MontlhyPayment">Durée ({dureeMois} mois) </label>
                    <input
                        type="range"
                        min={3}
                        max={60}
                        value={dureeMois}
                        id='MontlhyPayment'
                        onChange={(e) => setDureeMois(Number(e.target.value))}
                    />
                </div>
                <div className='SendSimulation'>
                    <h4> {isNaN(loyerMensuel) ? 0 : loyerMensuel.toFixed(2)} € / mois *</h4>
                    <Link className='buttonTall' to="/formulaire">Suivant</Link>
                </div>
                <small>* Simulation préliminaire non contractuelle. Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager. Des frais de dossiers peuvent s’appliquer.</small>
            </div>
        </div>
    );
}

export default Simulation;
