import './StarRating.css';

export default function StarRating({ rating }) {
    return (
      <div>
        {[...Array(5)].map((_, i) => (
          <span key={i} className={`star ${i < rating ? 'filled' : ''}`}>
            ★
          </span>
        ))}
      </div>
    );
  }
  