const sliderData ={
    questions: [
        {
            id: 1,
            text:[
                "💡 Vos services deux roues en 1 clic: financement, assurance, assistance, garantie, maintenance. ",
                "Les informations que vous nous indiquerez en 2 minutes nous permettront de vous proposer les conditions les plus avantageuses. ",
            ],
            type: "start",
        },
    ]
}
export default sliderData;