const sliderDataNeed = {
    title: "Besoins",
    questions: [
        {
            id: 1,
            label: "Êtes-vous un client particulier ou professionnel ?",
            type: "radio",
            option: ["Particulier", "Professionnel"],
            name: "CustomerType"
        },
        {
            id: 2,
            label: "Quel est votre besoin ?",
            type: "select",
            option: ["Financement + Service","Financement", "Service seul"],
            name: "CustomerNeed"
        },
        {
            id: 3,
            label: "En combien de fois voulez-vous financer votre véhicule ?",
            type: "range",
            min: 3,
            max: 60,
            name: "MonthlyPayment",
        },
    ]
}

export default sliderDataNeed;
