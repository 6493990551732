const sliderDataPersonalSituation = {
    title: "Situation personnelle",
    questions: [
        {
            id: 1,
            label: "Combien d'enfants avez-vous à charge ?",
            type: "number",
            name: "ChildrenInCharge",
            min: 0 
        },
        {
            id: 2,
            label: "Quelle est la situation de votre logement ?",
            type: "select",
            option: ["Propriétaire", "Locataire", "Logé par un tiers"],
            name: "HousingSituation"
        },
        {
            id: 3,
            label: "Combien payez-vous en loyer ou pour votre crédit immobilier ?",
            type: "number",
            name: "RentAmount",
            min: 0 
        },
        {
            id: 4,
            label: "Combien payez vous en charges ou autre crédits?",
            type: "text",
            name: "ChargesOrOtherCredits",
        },
    ]
}

export default sliderDataPersonalSituation;