const sliderData ={
    questions: [
        {
            id: 1,
            text:[
                "C'est fini 🛴🚲🛵 !",
                "Nous reviendrons vers vous avec un accord auprès d'une de nos banques partenaires !",
                "Notre équipe vous écrira ou vous rappellera dans les 24h pour trouver la solution qui vous convient."
            ],
            type: "end",
        },
    ]
}
export default sliderData;