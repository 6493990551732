import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SlideSelect.css';

function SlideSelect({ currentQuestion, transitionClass, linkedInput, currentValue, dataArr }) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (currentQuestion.name === "Brand") {
            fetchBrandsAndPopulateOptions();
        } else {
            setOptions(currentQuestion.option);
        }
    }, [currentQuestion.name, dataArr.VehicleType]); // Inclure dataArr.VehicleType dans les dépendances

    useEffect(() => {
        if (currentQuestion.name === "Brand" && options.length > 0) {
            if (!dataArr[currentQuestion.name]) {
                linkedInput(currentQuestion.name, options[0]); // Sélectionne le premier élément par défaut si non sélectionné
            }
        } else if (dataArr[currentQuestion.name]) {
            // Ne faites rien si une valeur est déjà sélectionnée
        } else if (options.length > 0) {
            linkedInput(currentQuestion.name, options[0]); // Sélectionne le premier élément par défaut pour les autres questions
        }
    }, [options]);


    const fetchBrandsAndPopulateOptions = async () => {
        try {
            const response = await axios.get('https://server.sikomobility.com/api/vehicle/catalogue');
            const brands = response.data.reduce((uniqueBrands, brandData) => {
                // Vérifier si la marque existe déjà dans le tableau uniqueBrands
                const existingBrand = uniqueBrands.find(brand => brand === brandData.Brand);
    
                // Si la marque n'existe pas déjà et correspond à VehicleType, l'ajouter au tableau
                if (!existingBrand && brandData.VehicleType === dataArr.VehicleType) {
                    uniqueBrands.push(brandData.Brand);
                }
    
                return uniqueBrands;
            }, []);
    
            setOptions(brands);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectChange = (e) => {
        linkedInput(e.target.name, e.target.value);
    };

    return (
        <div className={`Slide ${transitionClass}`}>
            <div className='inputDiv' key={currentQuestion.id}>
                <select
                    id={currentQuestion.name}
                    name={currentQuestion.name}
                    onChange={handleSelectChange}
                    value={currentValue}
                    required
                >
                    {currentQuestion.name === "Brand" && <option value="Autre">Autre</option>}
                    {options.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
                <span></span>
                <label htmlFor={currentQuestion.name}><h4>{currentQuestion.label}</h4></label>
            </div>
        </div>
    );
}

export default SlideSelect;