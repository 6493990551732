import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { sendNameStore, sendMailStore } from '../../Redux/slice';
import { useParams } from 'react-router-dom';
import SlideSelect from '../../Components/SlideSelect/SlideSelect';
import SlideRadio from '../../Components/SlideRadio/SlideRadio';
import SlideInput from '../../Components/SlideInput/SlideInput';
import SlideAdress from '../../Components/SlideAdress/SlideAdress';
import SlideStartEnd from '../../Components/SlideStartEnd/SlideStartEnd';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import Titre from '../../Components/Titre/Titre';
import dataStart from '../../Data/dataStart';
import dataQuestion from '../../Data/dataQuestion';
import dataQuestionVehicule from '../../Data/dataQuestionVehicule';
import dataQuestionBesoin from '../../Data/dataQuestionBesoin';
import dataQuestionPerso from '../../Data/dataQuestionPerso';
import dataQuestionPro from '../../Data/dataQuestionPro';
import dataEnd from '../../Data/dataEnd';
import Notif from '../../Components/Notif/Notif'
import axios from 'axios';
import './Formulaire.css';

function Formulaire() {

	const { nameStoretemp } = useParams();
	const storeNameStore = useSelector((state) => state.nameStore.Name);
	const dispatch = useDispatch();

	let finalNameStore;

	if (nameStoretemp) {
		finalNameStore = nameStoretemp;
	} else if (storeNameStore) {
		finalNameStore = storeNameStore;
	} else {
		finalNameStore = "Siko Mobility";
	}

    useEffect(() => {
        dispatch(sendNameStore(finalNameStore));

        console.log(finalNameStore);
		
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://localhost:8000/api/merchant?Name=${finalNameStore}`);
                if (response.data.Email) {
                    dispatch(sendMailStore(response.data.Email));
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [finalNameStore, dispatch]);

    // const MailStore = useSelector((state) => state.nameStore);

	const today = new Date();
	const dd = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

	const [sectionIndex, setSectionIndex] = useState(0);
	const [dataIndex, setDataIndex] = useState(0);
	const [dataArr, setDataArr] = useState({});
	const [transitionClass, setTransitionClass] = useState('');

	const sections = [dataStart,dataQuestion, dataQuestionVehicule, dataQuestionBesoin, dataQuestionPerso,dataQuestionPro,dataEnd];
	const currentSection = sections[sectionIndex];
	const currentTitle = currentSection.title;
	const currentQuestions = currentSection.questions;
	const currentQuestion = currentQuestions[dataIndex];
	const currentValue = dataArr[currentQuestion.name] ?? '';
	const handlePreviousDisabled = dataIndex === 0 && dataArr.length === 0;


	const linkedInput = (name, value) => {
		setDataArr(prevDataArr => ({
			...prevDataArr,
			[name]: value
		}));
	};

    const [notification, setNotification] = useState(null);

	const closeNotification = () => {
        setNotification(null);
    };

	useEffect(() => {
		console.log(dataArr);
	}, [dataArr]);

	const handlePrevious = () => {
		setTransitionClass('slide-up');
		setTimeout(() => {
			if (dataIndex > 0) {
				setDataIndex(prevIndex => prevIndex - 1);
			} else if (sectionIndex > 0) {
				setSectionIndex(prevIndex => prevIndex - 1);
				const previousSectionQuestions = sections[sectionIndex - 1].questions;
				setDataIndex(previousSectionQuestions.length - 1);
			} else {
				console.log("C'est la première question d'un questionnaire, vous ne pouvez pas revenir en arrière.");
			}
		}, 300);
	};

	const handleNext = async (e) => {
		e.preventDefault();
	
		if (currentQuestion.required && currentValue === '') {
			setNotification({
				type: 'error',
				message: 'Veuillez remplir le champ avant de continuer',
			});
			return;
		} else if (currentQuestion.type === 'email' && !currentValue.includes('@')) {
			setNotification({
				type: 'error',
				message: 'Veuillez entrer une email valide',
			});
			return;
		} else if (currentQuestion.type === 'tel' && currentValue.length !== 10) {
			setNotification({
				type: 'error',
				message: 'Veuillez entrer un numéro de téléphone valide',
			});
			return;
		} else if (currentQuestion.type === 'number' && currentValue < 0) {
			setNotification({
				type: 'error',
				message: 'Veuillez entrer un nombre positif',
			});
			return;
		} else if (currentQuestion.type === 'range' && currentValue < 0) {

			setNotification({
				type: 'error',
				message: 'Veuillez entrer un nombre positif',
			});
			return;
		} else if (currentQuestion.type === 'adress'  && !dataArr[currentQuestion.name]) {
			setNotification({
				type: 'error',
				message: 'Vous devez sélectionner une adresse dans le menu déroulant',
			});
			return;
		}
		setTransitionClass('');
		setTimeout(async () => {
			if (dataIndex < currentQuestions.length - 1) {
				setTransitionClass('slide-up');
				setDataIndex(prevIndex => prevIndex + 1);
			} else {
				if (sectionIndex < sections.length - 1) {
					setSectionIndex(prevIndex => prevIndex + 1);
					setDataIndex(0);
					const makeForm = {
						phoneNumber: dataArr.Phone || '',
						birthDate: dataArr.BirthDate || '',
						birthCity: dataArr.BirthLocation ? dataArr.BirthLocation.City || '' : '',
						birthZipCode: dataArr.BirthLocation ? dataArr.BirthLocation.ZipCode || '' : '',
						kidsnumber: dataArr.ChildrenInCharge || null,
						address: dataArr.AdressLocation ? dataArr.AdressLocation.Address || '' : '',
						zipCode: dataArr.AdressLocation ? dataArr.AdressLocation.ZipCode || '' : '',
						city: dataArr.AdressLocation ? dataArr.AdressLocation.City || '' : '',
						entrydate: dataArr.ContractStartDate || '',
						company: dataArr.CompanyName || '',
						companycity: dataArr.WorkLocation ? dataArr.WorkLocation.City || '' : '',
						companyzipcode: dataArr.WorkLocation ? dataArr.WorkLocation.ZipCode || '' : '',
						salary: dataArr.NetMonthlyIncome || null,
						rent: dataArr.RentAmount || null,
						total: null,
						lastName: dataArr.LastName || '',
						firstName: dataArr.FirstName || '',
						email: dataArr.Mail || '',
						duration: dataArr.MonthlyPayment || null,
						civility: dataArr.Civility || '',
						birthname: dataArr.BirthName || '',
						need: 'Paiement X fois',
						birthCountry: dataArr.BirthLocation ? dataArr.BirthLocation.Country || '' : '',
						bank: "TypeForm",
						returnCode: null,
						returnCodeService: null,
						vehicleType: dataArr.VehicleType || '',
						partner: finalNameStore || '',
						id: null, 
						clienttype: dataArr.CustomerType || '',
						cnir: null,
						cniv: null,
						jdd: null,
						bs: null,
						Autre1: null,
						Autre2: null,
						Autre3: null,
						Bilan: null,
						KBIS: null,
						RIB: null,
						magicallink: null,
						lastpage: null,
						orderDate: dd, // Vérifiez comment vous obtenez cette date
						brand: dataArr.Brand || '',
						model: dataArr.Model || '',
						amount: dataArr.VehiclePrice || null,
						scheduledAmount: null,
						insuranceContract: null,
						insuranceContractPrice: null,
						warrantyContract: null,
						warrantyContractPrice: null,
						maintenanceContract: null,
						maintenanceContractPrice: null,
						assistanceContract: null,
						assistanceContractPrice: null,
						scheduledDate: null,
						HousingSituation: dataArr.HousingSituation || '',
						ChargesOrOtherCredits: dataArr.ChargesOrOtherCredits || null,
						WorkContractType: dataArr.WorkContractType || '',
						WorkLocation: dataArr.WorkLocation ? dataArr.WorkLocation.Address || '' : '',
					};

					try {
						const response = await axios.post(
							'https://hook.eu1.make.com/2fja1txltfhzseqiqc8d1l46wel5j95n',
							makeForm
						);
					} catch (error) {
						console.error('Erreur API :', error);
					}
				} else {
					setNotification({
						type: 'success',
						message: 'Votre demande a bien été envoyée',
					});
				}
			}
		}, 300);


		if (dataArr.MonthlyPayment === "3" || dataArr.MonthlyPayment === "4" || dataArr.MonthlyPayment === "10") {
			window.location.href = "https://paiement.sikomobility.com/";
		}

		if (dataArr.CustomerNeed === "Service seul") {
			window.location.href = "https://service.sikomobility.com/";
		}
	};

	return (
		<div className='Content'>
			{currentTitle &&(
				<Titre title={currentTitle} />
			)}
			<ProgressBar total={currentQuestions.length} current={dataIndex + 1} />

			<Notif
                type={notification && notification.type}
                message={notification && notification.message}
                onClick={closeNotification}
            />

			<form onSubmit={handleNext} className='TypeForm'>
					{currentQuestion.type === 'start' || currentQuestion.type === 'end' ? (
						<SlideStartEnd
							currentQuestion={currentQuestion}
							transitionClass={transitionClass}
							linkedInput={linkedInput}
							currentValue={currentValue}
						/>
					) : null}

					{currentQuestion.type === 'email' || currentQuestion.type === 'number' || currentQuestion.type === 'text'|| currentQuestion.type === 'tel' || currentQuestion.type === 'date' || currentQuestion.type === 'month' || currentQuestion.type === 'range' ? (
						<SlideInput
							currentQuestion={currentQuestion}
							transitionClass={transitionClass}
							linkedInput={linkedInput}
							currentValue={currentValue}
						/>
					): null}

					{currentQuestion.type === 'select' && (
						<SlideSelect
							currentQuestion={currentQuestion}
							transitionClass={transitionClass}
							linkedInput={linkedInput}
							currentValue={currentValue}
							dataArr={dataArr}
						/>
					)}

					{currentQuestion.type === 'radio' && (
						<SlideRadio
							currentQuestion={currentQuestion}
							transitionClass={transitionClass}
							linkedInput={linkedInput}
							currentValue={currentValue}
						/>
					)}

					{currentQuestion.type === 'adress' && (
						<SlideAdress
							currentQuestion={currentQuestion}
							transitionClass={transitionClass}
							linkedInput={linkedInput}
							currentValue={currentValue}
						/>
					)}

					<div className='buttonDiv'>
						{currentQuestion.type !== 'start'&&
							(
								<p className='buttonTall' onClick={handlePrevious} disabled={handlePreviousDisabled}>Précédent</p>
							)
						}

						{
							currentQuestion.type !== 'end' &&(
								<button className='buttonTall' type='Submit' >
									{currentQuestion.type=== 'start'?
										(
											<>
												Je fais ma demande
											</>
										):(
											<>
												Suivant
											</>
										)
									}
								</button>
							)
						}
						
					</div>
			</form>

			
		</div>
	);
}

export default Formulaire;