import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector , useDispatch} from 'react-redux';
import { sendNameStore, sendMailStore } from '../../Redux/slice';
import Financement from '../../Asset/png/Financement.png'
import Paiement from '../../Asset/png/Paiement.png'
import Mobilite from '../../Asset/png/Mobilite.png'
import Titre from '../../Components/Titre/Titre'
import CardAvis from '../../Components/CardAvis/CardAvis'
import Modal from '../../Components/Modal/Modal'
import axios from 'axios';
import './Home.css'

export default function Home() {

    const { nameStoretemp } = useParams();
	const storeNameStore = useSelector((state) => state.nameStore.Name);
	const dispatch = useDispatch();

	let finalNameStore;

	if (nameStoretemp) {
		finalNameStore = nameStoretemp;
	} else if (storeNameStore) {
		finalNameStore = storeNameStore;
	} else {
		finalNameStore = "Siko Mobility";
	}

    const [isPartnerModalOpen, setPartnerModalOpen] = useState(false);

    useEffect(() => {
        dispatch(sendNameStore(finalNameStore));

        console.log(finalNameStore);

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://server.sikomobility.com/api/merchant?Name=${finalNameStore}`);
                console.log(response.data);
                if (response.data.Email) {
                    dispatch(sendMailStore(response.data.Email));
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [finalNameStore, dispatch]);

    const [formValues, setFormValues] = useState({
        storeName: '',
        storeEmail: '',
        storePhone: '',
        storeAddress: '',
        Moto: false,
        Scooter: false,
        Quad : false,
        Velo: false,
        Trottinettes: false,
        Other : false
    });

    function handleInputChange(e) {
        const { name, value, type, checked } = e.target;
    
        if (type === "checkbox") {
            setFormValues(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setFormValues(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    

    const togglePartnerModal = () => {
        setPartnerModalOpen(!isPartnerModalOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post('https://hook.eu1.make.com/9xe0gkn5aavtl9swsw1fka8avoh522hl', formValues);
            // Handle the response as needed
            console.log('Form data sent successfully', response);
        } catch (error) {
            // Handle any errors that occur during the request
            console.error('Error sending form data', error);
        }
    
        togglePartnerModal(); // Close the modal after sending the data
    };
    

    const reviews = [
        { Name: "Robin Mourier", Avis: "Simple, rapide et efficace ! Une équipe à l'écoute ! Je recommande vivement !", rating: 5 },
        { Name: "Wady Talhaoui", Avis: "Sérieux, à l’écoute et très disponible je recommande fortement cette entreprise les yeux fermés !!!", rating: 5 },
        { Name: "Youssouf SANOGO", Avis: "Excellente expérience humaine vécue avec Tristan de Siko qui me guidera sur chaques routes ! Merci encore 🙏", rating: 5 },
        { Name: "kevin vital", Avis: "Tres bonne expérience, tres accueillant et a l'écoute. Je recommande pour les solutions de financement 😁😁", rating: 5 },
        { Name: "Rak", Avis: "Société très professionnel,et réactive Tres bonne communication 👌", rating: 5 },
        { Name: "Manelle BC", Avis: "Je recommande a 100%. En quelques mots : Rapide, efficace et c’est un plaisir d’échanger avec eux par téléphone 😊", rating: 5 },
        { Name: "Yves-Roland BATAMIO", Avis: "Très bonne expérience, bonne communication, personnel disponible et réactif m, je recommande.", rating: 5 },
        { Name: "Kebaili Hacen", Avis: "Merciii  pour l assistance  apporter  de l équipe et aux employer qui sont à l écoute et font tout pour nous aider merci encor et à bientôt", rating: 4 },
        { Name: "Quentin Berthe", Avis: "Top , Je recommande Siko Mobility pour leur professionnalisme . Tristan D’Halluin et au top très à l’écoute du client . 👌", rating: 5 },
        { Name: "Arthur Joseph", Avis: "Équipe disponible et professionnel, un grand merci !", rating: 4 },
    ];

    return (
        <div className='Content'>
            <Titre title={"Découvrez notre gamme de services pour toutes les mobilités"}/>
            <div className="LinkDiv">
                <a href="https://paiement.sikomobility.com">
                    <img src={Financement} alt="Financement" />
                    <div>
                        <h1>PAIEMENT <br/> 3/4/10 FOIS</h1>
                        <h4>Faites un paiement en 3, 4 ou 10 fois en carte bleue. C'est sans justificatif.</h4>
                    </div>
                    
                </a>
                <Link to="/formulaire">
                    <img src={Paiement} alt="Paiement" />
                    <div>
                        <h1>FINANCEMENT <br/> 12 A 60 MOIS</h1>
                        <h4>Faites une demande de financement de 12 à 60 fois. Obtenez un accord d'une de nos banques partenaires.</h4>
                    </div>
                    
                </Link>
                <Link to="/service">
                    <img src={Mobilite} alt="Mobilite" />
                    <div>
                        <h1>FORFAITS <br/> MOBILITÉ</h1>
                        <h4>Souscrivez à un abonnement assurance, assistance, garantie ou maintenance. Sans engagement.</h4>    
                    </div>
                    
                </Link>
            </div>

            <div className="AvisDiv">
                <h2>Ils nous ont fait confiance ! À votre tour ! </h2> 
                <div className="Avis">
                    {reviews.map((review, index) => 
                        (
                            <CardAvis key={index} {...review} />
                        )
                    )}
                </div>
            </div>

            {isPartnerModalOpen && (
                <Modal className="popOverlay">
                    <form className='FormPartner' onSubmit={handleSubmit}>
                        
                        <div className="inputDiv">
                            <input type="text" id="storeName" name="storeName" onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="storeName">Nom du magasin</label>
                        </div>
                
                        <div className="inputDiv">
                            <input type="email" id="storeEmail" name="storeEmail" onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="storeEmail">Email</label>
                        </div>
                
                        <div className="inputDiv">
                            <input type="tel" id="storePhone" name="storePhone" onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="storePhone">Téléphone</label>
                        </div>
                
                        <div className="inputDiv">
                            <input type="text" id="storeAddress" name="storeAddress" onChange={handleInputChange} required />
                            <span></span>
                            <label htmlFor="storeAddress">Adresse</label>
                        </div>

                        <h4>Que vendez-vous ?</h4>
                
                        <div className='divCheckbox'>
                            <label>
                                <input type="checkbox" name="Moto" value="Moto" onChange={handleInputChange} /> Moto
                            </label>
                            <label>
                                <input type="checkbox" name="Scooter" value="Scooter" onChange={handleInputChange} /> Scooter
                            </label>
                            <label>
                                <input type="checkbox" name="Quad" value="Quad" onChange={handleInputChange} /> Quad
                            </label>
                            <label>
                                <input type="checkbox" name="Velo" value="Vélo" onChange={handleInputChange} /> Vélo
                            </label>
                            <label>
                                <input type="checkbox" name="Trottinettes" value="Trottinettes" onChange={handleInputChange} /> Trottinette
                            </label>
                            <label>
                                <input type="checkbox" name="Other" value="Autre" onChange={handleInputChange} /> Autre
                            </label>
                        </div>
                        <div className='buttonDiv'>
                            <p className='buttonBlue' onClick={togglePartnerModal}>Fermer</p>
                            <button className='buttonBlue' type="submit">Envoyer</button>
                        </div>
                    </form>
                </Modal>
            
            )}

            <div className="NewPartenerDiv">
                <h3>Vous avez un site e-commerce ou une boutique ?</h3>
                <div className='NewPartener'>
                    <div>
                        <small>Rejoignez nos partenaires et offrez de multiples services et avantages à vos clients.</small> <br/>
                        <small>Augmentez votre chiffre d’affaire avec notre solution en financement.</small> <br/>
                    </div>
                    <button className='buttonBlue' onClick={togglePartnerModal}>DEVENIR PARTENAIRE</button>
                </div>
            </div>
            
        </div>
    )
}
