import React from 'react'
import Paypng from '../../../Asset/png/pay.png'
import { Link } from "react-router-dom";

export default function Pay() {
  return (
    <Link to="/service/final">
        <img className='fake' src={Paypng} alt="Paypng" />
    </Link>
  )
}
