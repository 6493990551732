import'./Titre.css';


function Titre({ title}) {

    return (
        <div className="Titre" >
            <h1>{title}</h1>
        </div>
    ) ;
};

export default Titre;
