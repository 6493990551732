import React from 'react'
import servicepng from '../../../Asset/png/service.png'
import { Link } from "react-router-dom";

export default function Service() {
  return (
    <Link to="/service/pay">
        <img className='fake' src={servicepng} alt="servicepng" />
    </Link>
  )
}
