const currentDate = new Date();

const sliderDataProfessionalSituation = {
    title: "Situation professionnelle",
    questions: [
        {
            id: 1,
            label: "Quel est votre revenu net mensuel en euros ?",
            type: "number",
            name: "NetMonthlyIncome",
            min: 0
        },
        {
            id: 2,
            label: "Dans quel magasin souhaitez vous passer commande?",
            type: "text",
            name: "StoreName"
        },
        {
            id: 2,
            label: "Quel type de contrat de travail avez-vous ?",
            type: "select",
            option: ["CDI", "CDD", "Intérim", "Freelance", "Autre"],
            name: "WorkContractType"
        },
        {
            id: 3,
            label: "Depuis quand avez-vous ce contrat de travail?",
            type: "month",
            max: currentDate.toISOString().split('T')[0],
            name: "ContractStartDate"
        },
        {
            id: 4,
            label: "Pour quelle société travaillez-vous ?",
            type: "text",
            name: "CompanyName"
        },
        {
            id: 5,
            label: "Dans quelle ville travaillez vous?",
            type: "adress",
            name: "WorkLocation",
        }
    ]
}

export default sliderDataProfessionalSituation;

