import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from "./Containers/Home/Home";
import Formulaire from "./Containers/Formulaire/Formulaire";
import Simulation from "./Containers/Simulation/Simulation";
import ServiceHome from "./Containers/Service/Home/Home";
import ServiceService from "./Containers/Service/Service/Service";
import ServicePay from "./Containers/Service/Pay/Pay";
import ServiceFinal from "./Containers/Service/Final/Final";
import Error from "./Containers/Error/Error";
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';

function App() {
    const location = useLocation();
    const isHomePath = /^\/[^/]*$/.test(location.pathname);

    return (
        <div className="App">
            <Header isHomePath={isHomePath} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/:nameStoretemp" element={<Home />} />
                <Route path="/Service/" element={<ServiceHome />} />
                <Route path="/Service/service" element={<ServiceService />} />
                <Route path="/Service/pay" element={<ServicePay />} />
                <Route path="/Service/final" element={<ServiceFinal />} />
                <Route>
                    <Route path="formulaire" element={<Formulaire />} />
                    <Route path="formulaire/:nameStoretemp" element={<Formulaire />} />
                    <Route path="simulation" element={<Simulation />} />
                    <Route path="simulation/:nameStoretemp" element={<Simulation />} />
                </Route>
                <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
