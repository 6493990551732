import './CardAvis.css';
import StarRating from '../StarRating/StarRating';

export default function CardAvis(props) {
    return (
      <div className="AvisCard">
          <h3>{props.Name}</h3>
          <StarRating rating={props.rating} />
          <p>{props.Avis}</p>
      </div>
    )
  }
  