import React from 'react'
import homepng from '../../../Asset/png/home.png'
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <Link to="/service/service">
      <img className='fake' src={homepng} alt="homepng" />
    </Link>
  )
}
