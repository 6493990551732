const currentDate = new Date();
const minDate = new Date();
const maxDate = new Date();

minDate.setFullYear(currentDate.getFullYear() - 90);
maxDate.setFullYear(currentDate.getFullYear() - 10);  

const sliderData ={
    title: "Identité",
    questions: [
        {
            id: 1,
            label: "Quel est votre mail ? ",
            type: "email",
            name: "Mail"
        },
        {
            id: 2,
            label: "Quel est votre numéro de téléphone portable ?",
            type: "tel",
            name: "Phone"
        },
        {
            id: 3,
            label: "Quel est votre prénom?",
            type: "text",
            name: "FirstName"
        },
        {
            id: 4,
            label: "Quel est votre nom?",
            type: "text",
            name: "LastName"
        },
        {
            id: 5,
            label: "Quelle est votre civilité ?",
            type: "radio",
            option: ["Mr", "Mme"],
            name: "Civility"
        },
        {
            id: 6,
            label: "Quel est votre nom de naissance?",
            type: "text",
            name: "BirthName"
        },
        {
            id: 7,
            label: "Quelle est votre date de naissance ?",
            type: "date",
            min: minDate.toISOString().split('T')[0],
            max: maxDate.toISOString().split('T')[0],
            name: "BirthDate"
        },
        {
            id: 8,
            label: "Dans quelle ville êtes vous né?",
            type: "adress",
            name: "BirthLocation",
        },
        
        {
            id: 9,
            label: "Quel est l'adresse ou vous habitez ?",
            type: "adress",
            name: "AdressLocation",
        },
    ]
}
export default sliderData;