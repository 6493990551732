import './SlideStartEnd.css';

function SlideStartEnd({ currentQuestion, transitionClass}) {

    return (
        <div className={`Slide ${transitionClass}`}>

            {currentQuestion.text.map((question , index) => (
                <h2 key={index}>{question}</h2>
            ))}
            
        </div>
    );
}

export default SlideStartEnd;
