import React, { useState, useEffect } from 'react';
import Simulation from '../../Containers/Simulation/Simulation';
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../Asset/svg/logoblack.svg";
import Modal from '../../Components/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Calculatrice from '../../Asset/png/calculatrice.png';

function Header({ isHomePath }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const toggleBurgerMenu = () => {
        setIsBurgerOpen(!isBurgerOpen);
    };

    return (
        <>
            <div className="header">
                <Link to='/'><img src={logo} alt="logo"/></Link>

                {isHomePath && windowWidth > 1024 && (
                    <div className="header-links">
                        <button className="button" onClick={toggleModal}>Simulateur</button>
                        <a href="https://app.sikomobility.com">Espace marchand</a>
                        <a href="https://my.sikomobility.com">Espace client</a>
                    </div>
                )}

                {isHomePath && windowWidth <= 1024 && (
                    <div className="burger-icon" onClick={toggleBurgerMenu}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                )}
            </div>

            {isHomePath && windowWidth <= 1024 && (
                    <div className='Content'>
                        <div className='SimulationDiv'>
                            <img src={Calculatrice} alt="Calculatrice" />
                            <p> Calculez votre mensualité de 3 à 60 fois </p>
                            <button className="button" onClick={toggleModal}>Simulateur</button>
                        </div>

                    </div>
                )
            }

            {isHomePath && isOpen && (
                <Modal className="popOverlay">
                    <Simulation Variable={true} functionVariable={toggleModal} />
                </Modal>
            )}

            {isHomePath && isBurgerOpen && (
                <Modal className="popOverlay">
                    <div className="burger-menu">
                        <a href="https://app.sikomobility.com">Espace marchand</a>
                        <a href="https://my.sikomobility.com">Espace client</a>
                        <p onClick={toggleBurgerMenu}>Fermer</p>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default Header;
