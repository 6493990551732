const sliderDataVehicle = {
    title: "Véhicule",
    questions: [
        {
            id: 1,
            label: "Quel est le véhicule que vous voulez financer ?",
            type: "select",
            option: ["Moto", "Scooter", "Trottinette", "Velo", "Cargo"],
            name: "VehicleType"
        },
        {
            id: 2,
            label: "Quelle est la marque du véhicule?",
            type: "select",
            name: "Brand",
            option: []
        },
        {
            id: 3,
            label: "Quel est le modèle du véhicule?",
            type: "text",
            name: "Model"
        },
        {
            id: 4,
            label: "Quel est le prix du véhicule?",
            type: "number",
            name: "VehiclePrice",
            min: 0 
        }
    ]
};

export default sliderDataVehicle;
